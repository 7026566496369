.add-btn {
	background-color: transparent;
	height: 28px;
	width: 28px;
	padding: 5px;
	border: 2px solid var(--main-color-1);
	border-radius: 50%;
	color: var(--main-color-1);
	transition: background-color .2s, box-shadow .2s;
}

.add-btn>div {
	background-color: var(--main-color-0);
	height: 2px;
	border-radius: 1px;
}

.add-btn>div:first-child {
	transform: translateY(50%) rotate(90deg);
}

.add-btn>div:last-child {
	transform: translateY(-50%);
}

.add-btn:hover {
	cursor: pointer;
	background-color: #56595e;
	box-shadow: 0 0 5px #56595e;
}

.add-btn:active {
	transform: scale(0.95);
}
