.about {
	height: 100%;
	max-width: var(--content-width);
	background: var(--main-color-0);
	border-radius: .5rem;
	padding: 0 1rem;
	margin: 1.5rem 0;
}

.about>h2 {
	margin: 1.25rem 0 .25rem 0;
}

.about>p {
	margin: .5rem 0 1rem 0;
}

.about .title {
	color: var(--main-text-color);
	text-decoration: none;
	text-align: center;
}

.about .title h1 {
	margin-bottom: .4em;
}

.about-nav {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: 1.5rem .5rem 1rem .5rem;
}

.about-nav .btn {
	margin: .25rem;
}

a {
	cursor: pointer;
}


@media only screen and (max-width: 460px) {
	.about {
		margin-top: 0;
		border-radius: 0 0 .5rem .5rem;
	}
}
