.settings-label {
	display: block;
	padding-bottom: 3px;
}

.settings-section,
.settings-hint {
	padding: .25rem 0;
}

.settings-hint {
	font-size: var(--font-size-small);
	margin: 0;
}
