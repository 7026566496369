.friend {
	margin: 5px 5px;
	padding: 5px 5px;
	text-align: left;
	background-color: var(--main-color-0);
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.friend .friend-info {
	display: flex;
	align-items: center;
}

.friend .friend-info a {
	display: flex;
	align-items: center;
}

.friend .buttons {
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
}

.friend .friend-info img {
	border-radius: 5px;
}

.friend .friend-personal {
	padding-left: 5px;
	word-break: break-word;
}

.friend .friend-personal .realname {
	font-style: italic;
}

.friend .buttons .btn:first-child {
	margin-right: 4px;
}

@media only screen and (max-width: 450px) {
	.friend .buttons {
		flex-direction: column;
		align-items: stretch;
	}
	.friend .buttons .btn:first-child {
		margin: 0 0 4px 0;
	}
}
