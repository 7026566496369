.d-none {
  display: none;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.v-centered {
  position: absolute;
  top: 50vh;
  transform: translateY(-50%);
}

.no-bg {
  background: none;
}

.no-br, .no-br-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.no-br, .no-br-bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.no-br-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.no-br-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.t-center {
  text-align: center;
}

#root {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  z-index: -1;
  color: var(--main-text-color);
  aspect-ratio: 960/540;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--main-color-1);
  background-image: url('../static/layered-waves.svg');
}

/* ===========================
  Create / Join Session Pages
============================== */

.create-session .steamId-input,
.join-session .steamId-input {
  display: flex;
}

.create-session .input-margin,
.join-session .input-margin {
  margin: .3rem 0 .75rem 0;
}

.create-session .steamId-input input,
.join-session .steamId-input input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0px;
}

.create-session .steamId-input .profile-url,
.join-session .steamId-input .profile-url {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  box-sizing: border-box;
  padding: 0 5px 2px 5px;
  border: 1px solid var(--main-color-2);
  background-color: var(--main-color-2);
  color: var(--main-text-color);
  font-size: var(--font-size-x-small);
  border-radius: 0.25rem 0 0 0.25rem;
}

.create-session, .join-session {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: .3rem;
}

.create-session .title,
.join-session .title {
  text-align: center;
  margin: .75rem 0;
}

.create-session .subtitle,
.join-session .subtitle {
  margin: 1rem 0 1.5rem 0;
  padding: .3rem;
  border-bottom: 2px solid var(--main-color-3);
}

.create-session form,
.join-session form {
  padding: 0 .3rem;
  background-color: var(--main-color-1);
}

.create-session .link-join,
.join-session .link-create {
  text-align: center;
  background-color: var(--main-color-1);
  border-radius: 0 0 5px 5px;
  padding: 1rem .3rem .5rem .3rem;
  margin: 0 0 .5rem 0;
}

.join-session input, .join-session label,
.create-session input, .create-session label {
  width: 100%;
  display: block;
}

.join-session input, .create-session input {
  padding: 5px 7px;
  height: 35px;
}
