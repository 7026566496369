.container {
	display: flex;
	justify-content: space-evenly;
	align-content: flex-start;
}

.container>div {
	width: 33%;
	margin: 0 .5rem auto .5rem;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	max-height: calc(100vh - 105px);
}

.container-tabs>div, .container-tabs>div.peers {
	height: calc(100vh - 160px);
}

.container-tabs>div {
	margin: 0 .5rem auto .5rem;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

@media only screen and (max-width: 460px) {
	.container-tabs>div, .container-tabs>div.peers {
		height: calc(100vh - 165px);
	}
}

/*========================
  Single column container
========================== */

.container-single {
	display: flex;
	align-items: stretch;
	justify-content: center;
	box-sizing: border-box;
	max-height: calc(100% - 24px);
	overflow-y: auto;
}

.container-single-inner {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: var(--content-width);
}
