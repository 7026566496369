.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow-x: hidden;
}

.header .settings {
	margin-left: auto;
}

.header .title {
	padding-left: 10px;
	margin: .5em 0;
	cursor: pointer;
}

.peers {
	margin-bottom: 0 !important;
}

.peers>div:last-child:not(.invite-box),
.peers .collapsed:last-of-type {
	border-radius: 0 0 5px 5px;
}

.settings-modal {
	background-color: var(--main-color-1);
	border-radius: 5px;
	padding: .75rem;
}

.settings-modal h2 {
	margin: 0 0 .75rem 0;
}

.show-settings-btn {
	margin-right: .5rem;
}

/* ==============
 Settings Header
================= */

.settings-header {
	display: flex;
	justify-content: space-between;
}

.settings-header .close-btn {
	border-color: var(--main-color-2);
	transform: rotate(45deg)
}

.settings-header .close-btn>div {
	background-color: var(--main-color-2);
}

.settings-header .close-btn:hover {
	background-color: inherit;
}
