.about-teaser {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: 100%;
	padding: 1rem 0 2rem 0;
}

.about-teaser .content {
	/* Hides scroll bar from collapsible */
	overflow-y: hidden;
}

/* =======
  Header
========== */

.about-teaser-header {
	text-align: left;
	border-bottom: 2px solid var(--main-color-3);
	border-radius: 5px 5px 0 0;
	background-color: var(--main-color-1);
	padding: 0 .3rem;
	transition: border .15s, color .15s;
}

.about-teaser-header>h2 {
	margin: .3rem 0;
}

.about-teaser-header .arrow-up .arrow-l,
.about-teaser-header .arrow-up .arrow-r,
.about-teaser-header .arrow-down .arrow-l,
.about-teaser-header .arrow-down .arrow-r {
	background-color: var(--main-color-3);
}

.about-teaser-header:hover .arrow-up .arrow-l,
.about-teaser-header:hover .arrow-up .arrow-r,
.about-teaser-header:hover .arrow-down .arrow-l,
.about-teaser-header:hover .arrow-down .arrow-r {
	background-color: var(--main-color-2);
}

.about-teaser-header:hover {
	background-color: var(--main-color-1) !important;
	border-color: var(--main-color-2);
}

/* =======
  Content
========== */

.about-teaser-content {
	padding: .3rem;
	background: var(--main-color-1);
	border-radius: 0 0 5px 5px;
}
