.error-popup-list {
	position: absolute;
	width: 100%;
	height: 0;
	display: flex;
	justify-content: center;
	z-index: 50;
}

.error-popup-list>div {
	width: var(--content-width);
	padding: .3rem;
}
