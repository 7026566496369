.invite-box {
	padding: 7px 10px 7px 10px;
	background-color: var(--main-color-0);
	border-radius: 5px;
}

.invite-box .invite-row {
	display: flex;
	margin-top: .2rem;
}

.invite-box .invite-row input {
	width: 100%;
}

.invite-box .invite-row .btn {
	margin-left: 4px;
}
