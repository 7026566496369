.group-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--main-color-3);
	border-radius: 5px;
	padding: 10px 10px;
}

.group-header .group-details h2 {
	width: 100%;
	margin: 0;
	margin-top: -2px;
}

.group-header .group-details div {
	width: 100%;
	font-size: smaller;
}
