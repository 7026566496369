.game {
	display: flex;
	align-items: stretch;
	text-align: left;
	padding: 4px 3px;
}

.game .img-link,
.game .game-info,
.game .match-info,
.game .dnd-icon {
	background-color: var(--main-color-0);
	padding: 7px 0;
}

.game .img-link {
	display: flex;
	align-items: center;
	border-radius: 5px 0 0 5px;
	padding-left: 8px;
}

.game .img-link.no-select {
	user-select: none;
}

.game .game-info {
	width: 100%;
	border-radius: 0 5px 5px 0;
	padding-right: 8px;
	padding-left: 5px;
}

.game .game-info.no-br {
	border-radius: 0;
	padding-right: 2px;
}

.game .img-link img, .game .no-icon {
	border-radius: 5px;
}

.game .no-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--main-color-0);
	background: var(--main-color-3);
	width: 30px;
	height: 30px;
}

.game .no-icon.custom {
	background-color: #4158D0;
	background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
}

.game .no-icon span {
	font-size: 20px;
	font-weight: bold;
	border-bottom: 0;
}

.game .game-info .playtime {
	font-size: var(--font-size-small);
}

.game .match-info {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	align-items: flex-end;
	border-radius: 0 5px 5px 0;
	padding-right: 8px;
}

.game .match-info .weight {
	color: var(--text-color-gray);
	font-size: var(--font-size-small);
}

.owner {
	border-radius: 8px;
	margin: 0 0 0 2px;
}

.game .dnd-icon {
	display: flex;
	align-items: center;
	border-radius: 0 5px 5px 0;
	padding-right: 14px;
}

.game .dnd-icon img {
	transition: filter 2s ease-in-out;
}

.game .dnd-icon img.highlight {
	filter: brightness(1.75);
}
