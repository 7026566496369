.games-list {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	background-color: var(--main-color-2);
	border-radius: 5px;
}

.scroll-container {
	height: 100%;
	overflow-y: scroll;
}
