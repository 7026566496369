.friends {
	width: var(--content-width);
	background-color: var(--main-color-2);
	border-radius: 5px;
	margin: .5rem .5rem calc(.5rem + 20px) .5rem;
}

.friends .friendslist-header {
	display: flex;
	margin: 5px 5px;
}

.friends .list {
	overflow-y: scroll;
	height: 87vh;
}

.friends .friendslist-header .friend-search {
	width: 100%;
	margin-right: 5px;
}
