/* =========
  Variables
============ */

:root {
  --main-color-0: #343538;
  --main-color-1: #282c34;
  --main-color-2: #4a4c50;
  --main-color-3: #777;
  --main-text-color: #fff;
  --text-color-gray: #7e7e7e;
  --color-red: rgb(190, 25, 25);
  --color-green: rgb(25, 140, 25);
  --content-width: clamp(30vw, 500px, 100vw);
  --font-size-small: min(calc(10px + .6vmin), 14px);
  --font-size-x-small: min(calc(9.5px + .4vmin), 13px);
}

/* ==========
  Typography
============= */

@font-face {
  font-family: SpaceGrotesk;
  font-weight: 300;
  src: url(./../static/fonts/SpaceGrotesk-Light.otf);
}

@font-face {
  font-family: SpaceGrotesk;
  font-weight: 700;
  src: url(./../static/fonts/SpaceGrotesk-Bold.otf);
}

body, button, input {
  font-family: SpaceGrotesk;
}

body, button, input {
  font-weight: 300;
}

h1, h2, h3 {
  font-weight: 700;
}

h1 {
  font-size: min(calc(18px + 1.5vmin), 32px);
}

h2 {
  font-size: min(calc(16px + 1vmin), 22px);
}

h3 {
  font-size: min(calc(14px + .5vmin), 18px);
}

body {
  font-size: min(calc(13px + .35vmin), 16px);
}

a {
  color: #61dafb;
}

/* =========
  Scrollbar
============ */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #dddddd;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: var(--main-color-3);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--main-color-2);
}

/* ======
  Layout
========= */

body {
  margin: 0;
}

input[type=text] {
  height: 35px;
  box-sizing: border-box;
  padding: 5px 7px;
  border: 1px solid var(--main-color-2);
  border-radius: .25rem;
  transition: border-color .2s;
  background-color: #fff;
  color: var(--main-color-2);
}

input[type=text]:focus {
  border-color: #a7c2df;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
