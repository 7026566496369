.footer {
	width: 100%;
	background-color: var(--main-color-0);
	position: fixed;
	bottom: 0;
	color: var(--text-color-gray);
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	z-index: 10;
}

.footer .f-left {
	text-align: left;
	padding: 0 .5rem;
	font-size: var(--font-size-x-small);
}

.footer .f-right {
	display: flex;
	align-items: center;
	padding: 0 .5rem;
	height: 24px;
}

.footer .f-right a, .footer .f-right div {
	padding-left: .5rem;
	color: var(--text-color-gray);
}

.footer .footer-link {
	transition: color .2s;
	font-size: var(--font-size-x-small);
}

.footer .footer-link:hover {
	color: #61dafb;
}

.footer img.github {
	padding-top: 4px;
}

.footer img.contact {
	padding-top: 2px;
}

.footer img {
	transition: .2s;
}

.footer img:hover {
	filter: brightness(.8);
	transform: scale(1.1);
}

@media only screen and (max-width: 460px) {
	.footer {
		flex-direction: column-reverse;
	}

	.footer .footer-link {
		padding-bottom: 1px;
	}

	.footer .f-left {
		text-align: center;
		padding-bottom: 2px;
	}

	.footer .f-right {
		height: 21.5px;
	}

	.footer .contact {
		width: 17px;
		height: 17px;
	}

	.footer .github {
		width: 15px;
		height: 15px;
	}
}
