.user-header {
	display: flex;
	align-items: flex-start;
	background-color: var(--main-color-3);
	border-radius: 5px;
	padding: 7px 7px;
}

.user-header.has-subheader {
	border-radius: 5px 5px 0 0;
	padding-bottom: 4px;
}

.user-header img {
	border-radius: 5px;
	margin-right: 7px;
}

.user-header .user-details {
	height: 100%;
}

.user-header .user-details h2 {
	width: 100%;
	margin: 0;
}

.user-header .user-details div {
	width: 100%;
	font-size: smaller;
}

.sub-header {
	display: flex;
	align-items: center;
	background-color: var(--main-color-3);
	padding: 0 7px 7px 7px;
	border-radius: 0 0 5px 5px;
}

.sub-header .btn {
	margin-left: 4px;
}

.sub-header input {
	width: 100%;
}

.sub-header span {
	margin-left: auto;
	padding: 0 4px 0 7px;
}

@media only screen and (max-width: 400px) {

	/* Moves buttons in new row bellow searchBar */
	.sub-header {
		flex-wrap: wrap;
	}

	.sub-header input {
		margin-bottom: 7px;
	}
}
