.blur-bg {
	position: absolute;
	z-index: 20;
	width: 100%;
	height: 100vh;
	backdrop-filter: blur(4px);
}

.modal {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal :not(.blur-bg) {
	z-index: 30;
}
